import { UserRole } from 'api/users';
import { useRoleGuard } from 'component/hooks/useRoleGuard';
import { ReactNode } from 'react';

interface RoleGuardProps {
  readonly roles: UserRole[];
  readonly type: 'allow' | 'block';
  readonly children: ReactNode;
  readonly fallback?: ReactNode;
}

export const RoleGuard = ({ roles, type, fallback, children }: RoleGuardProps) => {
  const passed = useRoleGuard(roles, type);

  if (type === 'allow') {
    // When the guard type is 'allow':
    // If a role was matched, render the content. Otherwise, block the content and render the fallback.
    return passed ? children : fallback;
  } else {
    // When the guard type is 'block':
    // If a role was matched, block the content and render the fallback. Otherwise, render the content.
    return passed ? fallback : children;
  }
};
