import { useQueryClient } from '@tanstack/react-query';
import { Task, useProfileTaskPolling } from 'api/profile';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { createContext, PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const BackgroundTaskContext = createContext<{
  readonly allTasks: Task[];
  readonly tasksInProgress: Task[];
  readonly newlyCompletedTasks: Task[];
}>({ allTasks: [], tasksInProgress: [], newlyCompletedTasks: [] });

export const BackgroundTaskProvider = ({ children }: PropsWithChildren) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const profile = useProfileTaskPolling();
  const queryClient = useQueryClient();

  const tasks = profile.data?.data.result?.tasks || [];
  const previousTasks = useRef(tasks);

  const getTaskNotificationMessage = (task: Task) => {
    const success = task.task_status === 'DONE';

    switch (task.task_type) {
      case 'backup':
        return success ? t('backup_created_successfully') : t('backup_creation_failed');
      case 'restore':
      case 'automated_restore':
        return success ? t('backup_restored_successfully') : t('backup_restoration_failed');
      default:
        return null;
    }
  };

  useEffect(() => {
    previousTasks.current = tasks;
  }, [tasks]);

  const tasksInProgress = useMemo(
    () => tasks.filter(task => task.task_status === 'PROGRESS' || task.task_status === 'NEW'),
    [tasks]
  );

  const newlyCompletedTasks = useMemo(
    () =>
      tasks.filter(
        task =>
          (task.task_status === 'DONE' || task.task_status === 'ERROR') &&
          previousTasks.current.find(
            prevTask =>
              prevTask.id === task.id &&
              (prevTask.task_status === 'PROGRESS' || prevTask.task_status === 'NEW')
          )
      ),
    [tasks]
  );

  useEffect(() => {
    // Check if any new tasks have finished and display a notification
    for (const task of contextValue.newlyCompletedTasks) {
      const message = getTaskNotificationMessage(task);

      if (message) {
        enqueueSnackbar(message, {
          variant: task.task_status === 'DONE' ? 'success' : 'error',
        });
      } else {
        console.error('TaskNotificationProvider: unknown task type', task);
      }
    }
  }, [newlyCompletedTasks]);

  useEffect(() => {
    // Invalidate queries related to completed tasks
    for (const task of contextValue.newlyCompletedTasks) {
      if (task.task_type === 'staging') {
        queryClient.invalidateQueries({ queryKey: [`siteDetail${task.site_id}`] });
      }
    }
  }, [newlyCompletedTasks, queryClient]);

  const contextValue = useMemo(
    () => ({
      allTasks: tasks,
      tasksInProgress,
      newlyCompletedTasks,
    }),
    [tasks]
  );

  return (
    <BackgroundTaskContext.Provider value={contextValue}>{children}</BackgroundTaskContext.Provider>
  );
};
