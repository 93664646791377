import { useProfile } from 'api/profile';
import { UserRole } from 'api/users';

type guardType = 'allow' | 'block';

export const useRoleGuard = (roles: UserRole[], type: guardType) => {
  const profile = useProfile();

  const profileRoles = profile.data?.data.result?.client?.roles || [];

  if (type === 'allow') {
    // Return true if we should allow access, false otherwise
    return roles.some(role => profileRoles.includes(role));
  } else {
    // Return true if we should block access, false otherwise

    // Block billing_admin if it's the only role
    if (
      roles.includes('billing_admin') &&
      profileRoles.includes('billing_admin') &&
      profileRoles.length === 1
    ) {
      return true;
    }

    // Otherwise, block access if any of the other roles are present
    return roles.some(role => role !== 'billing_admin' && profileRoles.includes(role));
  }
};
